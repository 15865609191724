import React from "react";
import UserTable from "./UserTable";
import Index from "./index";

const Users = () => {
    const {
        allUsers,
        loading,
        history,
        search,
        setSearch,
        currentPage,
        setCurrentPage,
        rows,
        setRows,
        first,
        setFirst,
        setFilterKey,
        setFilterData,
        setSearchText,
    } = Index();
    return (
        <UserTable
            heading="All Users"
            allUsers={allUsers}
            loading={loading}
            history={history}
            rows={rows}
            setRows={setRows}
            first={first}
            setFirst={setFirst}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            search={search}
            setSearch={setSearch}
            setFilterKey={setFilterKey}
            setFilterData={setFilterData}
            setSearchText={setSearchText}
        />
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};
export default React.memo(Users, comparisonFn);
