import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getUsers } from "../../redux/actions/userAction";
import useDebounce from "../../utils/hooks";

export default function Index() {
    const history = useHistory();
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const searchKeys = ["fullName", "email", "kycStatus"];
    const [search, setSearch] = useState("");
    const [searchText, setSearchText] = useDebounce(500, "");

    const [filterKey, setFilterKey] = useState("");
    const [filterData, setFilterData] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(getUsers(setLoading, currentPage, rows, searchKeys, search, filterKey, filterData));
    }, [dispatch, currentPage, rows, searchText, filterData]);

    let { allUsers } = useSelector((state) => state.users);

    const inActiveUsers = allUsers;

    return {
        allUsers,
        inActiveUsers,
        loading,
        history,
        currentPage,
        rows,
        setRows,
        setCurrentPage,
        search,
        setSearch,
        first,
        setFirst,
        setFilterKey,
        setFilterData,
        setSearchText
    };
}
