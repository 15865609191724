import React from "react";
import Index from "./index";
import PendingTransactionsTable from "./PendingTransactionsTable";

const PendingTransactions = ({ showSearch = true, heading, paginator = true, hideColumns = [] }) => {
    const {
        pendingTransactions,
        loading,
        history,
        search,
        setSearch,
        currentPage,
        setCurrentPage,
        rows,
        setRows,
        first,
        setFirst,
        setFilterData,
        setSearchText,
        searchKeys,
        filterData,
        setLoading,
    } = Index();

    return (
        <PendingTransactionsTable
            heading={heading ? heading : "Pending Transactions"}
            pendingTransactions={pendingTransactions}
            loading={loading}
            history={history}
            rows={rows}
            setRows={setRows}
            first={first}
            setFirst={setFirst}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            search={search}
            setSearch={setSearch}
            setFilterData={setFilterData}
            setSearchText={setSearchText}
            searchKeys={searchKeys}
            filterData={filterData}
            setLoading={setLoading}
            showSearch={showSearch}
            paginator={paginator}
            hideColumns={hideColumns}
        />
    );
};

// const comparisonFn = function (prevProps, nextProps) {
//     return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
// };

// export default React.memo(PendingTransactions, comparisonFn);
export default PendingTransactions;
