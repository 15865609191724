import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getGasFeesCurrencyList } from "../../redux/actions/gasFeesAction";

export default function Index() {

    const history = useHistory();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(getGasFeesCurrencyList(setLoading));
    }, [dispatch]);

    let { gasFees } = useSelector((state) => state.gasFees);

    return {
        gasFees
    };
}
