import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function CustomCard({ title, children, className, backable = true, ...props }) {
    const history = useHistory();
    return (
        <div className={`border border-1 border-300 h-100 label-table ${className}`}>
            <div className="flex mt-3">
                {backable ? <i className="pi pi-angle-left text-4xl my-auto cursor-pointer" onClick={() => history.goBack()} /> : null}
                <div className="text-2xl my-auto">{title}</div>
            </div>
            <div className="p-2 p-fluid p-5">{children}</div>
        </div>
    );
}
