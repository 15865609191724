import React, { useState } from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { useDispatch } from "react-redux";
import { UserEnableDisableAction } from "../../redux/actions/userAction";
import TableImage from "../../shared/TableCard/TableImage";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";
import * as moment from "moment";
import { capitalizeFirstLetter } from "../../utils/javascript";

export default function CustomerTable({
    heading,
    allUsers,
    loading,
    history,
    search,
    setSearch,
    setCurrentPage,
    rows,
    setRows,
    first,
    setFirst,
    setFilterData,
    setSearchText,
}) {
    const dispatch = useDispatch();
    const [selectedRow, setSelectedRow] = useState([]);
    const actions = (d) => {
        return (
            <div className="flex justify-content-around">
                <i className="pi pi-eye cursor-pointer" onClick={() => history.push(`users/view/${d._id}`)}></i>
                <i className="pi pi-pencil cursor-pointer" onClick={() => history.push(`users/edit/${d._id}`)}></i>
            </div>
        );
    };

    const handleSwitchChange = (id, deactivate) => {
        dispatch(UserEnableDisableAction(id, { deactivate }));
    };

    const isActive = (rowData) => {
        return (
            <div className="flex">
                <InputSwitch checked={!rowData.isDeleted} onChange={() => handleSwitchChange(rowData?._id, !rowData.isDeleted)} />
            </div>
        );
    };

    const image = (r) => {
        const image = r.image;
        return <TableImage image={image} />;
    };
    const dateFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value}
                onChange={(e) => {
                    options.filterCallback(e.value, options.index);
                }}
                dateFormat="yyyy-mm-dd"
                placeholder="yyyy-mm-dd"
                mask="99/99/9999"
            />
        );
    };

    const [filters, setFilters] = useState({
        createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        // subscriptionExpireOn: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    });

    const formatDate = (date) => {
        date = new Date(date);
        return date.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    };

    const kycVerified = (isVerfied) => {
        return isVerfied ? "Yes" : "No";
    };

    const applyDateFilter = (e) => {
        for (let key of e.constraints) {
            let filterData = {};
            if (key.matchMode === "Date is before") {
                filterData = { ...filterData, endDate: moment(key.value).format("YYYY-MM-DD") };
            } else if (key.matchMode === "Date is after") {
                filterData = { ...filterData, startDate: moment(key.value).format("YYYY-MM-DD") };
            } else {
                filterData = {
                    ...filterData,
                    startDate: moment(key.value).format("YYYY-MM-DD"),
                    endDate: moment(key.value).format("YYYY-MM-DD"),
                };
            }
            setFilterData((prev) => ({ ...prev, createdAt: filterData }));
        }
    };

    const onApplyFilters = (e) => {
        for (let key in e.filters) {
            if (key === "createdAt" && e.filters["createdAt"].constraints[0].value) {
                applyDateFilter(e.filters[key]);
            } else if (e.filters[key].constraints[0].value) {
                setFilterData((prev) => ({ ...prev, [key]: e.filters[key].constraints[0].value }));
            }
        }
    };

    const onFilterClear = (key) => {
        setFilterData((prev) => {
            delete prev[key];
            return {
                ...prev,
            };
        });
    };

    return (
        <>
            <TableCard
                title={heading}
                showSearch={true}
                onSearch={setSearch}
                searchKeyword={search}
                totalRecords={allUsers.count}
                first={first}
                setFirst={setFirst}
                setCurrentPage={setCurrentPage}
                rows={rows}
                setRows={setRows}
                setSearchText={setSearchText}
                paginator={true}
            >
                <DataTable
                    value={allUsers.users}
                    selection={selectedRow}
                    onSelectionChange={(e) => setSelectedRow(e.value)}
                    showGridlines
                    filters={filters}
                    filterDisplay="menu"
                    loading={loading}
                    emptyMessage="No users found."
                    rows={rows}
                    onFilter={onApplyFilters}
                >
                    <Column body={(rowData) => (rowData.firstName ? rowData.firstName + " " + rowData.lastName : "-")} header="Name"></Column>
                    <Column field="email" header="Email" style={{ width: "250px" }}></Column>
                    <Column
                        header="Created At"
                        field="createdAt"
                        dataType="date"
                        style={{ minWidth: "10rem" }}
                        filterMatchMode="dateIs"
                        body={(rowData) => (rowData.createdAt ? moment(rowData.createdAt).format("YYYY-MM-DD") : null)}
                        filter
                        filterType="custom"
                        filterElement={dateFilterTemplate}
                        showFilterOperator={false}
                        filterMatchModeOptions={["Date is", "Date is before", "Date is after"]}
                        onFilterClear={() => onFilterClear("createdAt")}
                    />
                    <Column
                        field="isVerified"
                        header="Tinbox Verified"
                        body={(rowData) => kycVerified(rowData.isVerified)}
                        style={{ width: "250px" }}
                    ></Column>
                    <Column
                        field="isKYCVerified"
                        header="KYC Verified"
                        body={(rowData) => kycVerified(rowData.isKYCVerified)}
                        style={{ width: "250px" }}
                    ></Column>
                    <Column
                        field="kycStatus"
                        header="KYC Status"
                        body={(rowData) => (rowData.kycStatus ? capitalizeFirstLetter(rowData.kycStatus) : "Not Initiated")}
                        style={{ width: "250px" }}
                    ></Column>
                    <Column
                        field="lastLogin"
                        header="Last Login"
                        body={(rowData) => (rowData.lastLogin ? moment(rowData.lastLogin).startOf("seconds").fromNow() : "-")}
                        style={{ width: "250px" }}
                    ></Column>
                    <Column header="Active" body={isActive} style={{ width: "80px" }}></Column>
                    <Column body={actions} style={{ width: "80px" }} header="Action"></Column>
                </DataTable>
            </TableCard>
        </>
    );
}
