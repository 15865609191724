import React, { useState } from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { updateGasFees } from "../../redux/actions/gasFeesAction";
import { useDispatch } from "react-redux";
import { CustomDropdown } from "../../shared/Input/CustomDropdown";

export default function GasFeesTable({ heading, gasFees }) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const onRowEditComplete = (e) => {
        dispatch(updateGasFees(e.data._id, e.newData, setLoading));
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const selectedExchangeRateTemplate = (options) => {
        return (
            <CustomDropdown
                options={["Market", "User"]}
                value={options.selectedExchangeRate}
                onChange={(e) => dispatch(updateGasFees(options._id, { selectedExchangeRate: e.value }, setLoading))}
            ></CustomDropdown>
        );
    };

    return (
        <>
            <TableCard title={heading} pagination={true}>
                <DataTable
                    value={gasFees}
                    selectionMode="checkbox"
                    showGridlines
                    loading={loading}
                    editMode="row"
                    onRowEditComplete={onRowEditComplete}
                    tableStyle={{ minWidth: "50rem" }}
                    emptyMessage="No gas fees currencies found."
                >
                    <Column body={(rowData, row) => row.rowIndex + 1} header="SrNo"></Column>
                    <Column body={(rowData, row) => `${rowData.firstCurrency}-${rowData.secondCurrency}`} header="Exchange Pair"></Column>
                    <Column
                        field="fees"
                        header="Gas Fee"
                        editor={(options) =>
                            `${options.rowData.firstCurrency}-${options.rowData.secondCurrency}` === "CAD-USD" ||
                            `${options.rowData.firstCurrency}-${options.rowData.secondCurrency}` === "USD-CAD"
                                ? null
                                : textEditor(options)
                        }
                        style={{ width: "20%" }}
                    ></Column>
                    <Column field="marketRate" header="Market Rate" style={{ width: "20%" }}></Column>
                    <Column
                        field="exchangePricePerUnit"
                        editor={(options) => textEditor(options)}
                        header="User Rate"
                        style={{ width: "20%" }}
                    ></Column>
                    <Column
                        name="selectedExchangeRate"
                        field="selectedExchangeRate"
                        header="Selected Exchange Rate"
                        body={(rowData) => selectedExchangeRateTemplate(rowData)}
                        style={{ width: "20%" }}
                    ></Column>

                    <Column
                        field="transactionFee"
                        header="Transaction Fee (%)"
                        editor={(options) =>
                            `${options.rowData.firstCurrency}-${options.rowData.secondCurrency}` === "CAD-USD" ||
                            `${options.rowData.firstCurrency}-${options.rowData.secondCurrency}` === "USD-CAD"
                                ? textEditor(options)
                                : null
                        }
                        style={{ width: "20%" }}
                    ></Column>
                    <Column rowEditor headerStyle={{ width: "10%", minWidth: "8rem" }} bodyStyle={{ textAlign: "center" }}></Column>
                </DataTable>
            </TableCard>
        </>
    );
}
