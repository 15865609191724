import api from "../../services/api";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";

export const getUsersCount = (returnData) => async (dispatch) => {
    const res = await api("get", Constants.END_POINT.GET_USERS_COUNT);
    if (res.success) {
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    }
};

export const sendNotification = (data, setLoading, setData, selectedRow, setSelectedRow) => async (dispatch) => {
    setLoading(true);

    const payload = {
        ...data,
        userIds: selectedRow?.map((item) => item._id),
    };

    const res = await api("post", Constants.END_POINT.NOTIFICATION, payload);
    if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        setData({
            title: "",
            description: "",
        });
        setSelectedRow([]);
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        setData({
            title: "",
            description: "",
        });
    }
    setLoading(false);
};
export const ChangeAppDescAction = (data, setLoading, setData) => async (dispatch) => {
    setLoading(true);

    const res = await api("post", Constants.END_POINT.CHANGE_APP_DESC, data);
    if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        setData(res.data);
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
};

export const getAppDescAction = (next) => async (dispatch) => {
    const res = await api("get", Constants.END_POINT.CHANGE_APP_DESC);
    if (res.success) {
        next(res.data);
    }
};
