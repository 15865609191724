import { type } from "@testing-library/user-event/dist/type";
import { types } from "../types/types";
const intitalState = {
    allTransactions: {
        transactions: [],
        count: 0
    },
    pendingTransactions: {
        transactions: [],
        count: 0
    },
    pendingCount: 0,
    completedCount: 0,
    transactionsByYear: []
};

const usersReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ALL_TRANSACTIONS:
            return {
                ...state,
                allTransactions: { transactions: action.payload, count: action.count },
            };
        case types.PENDING_TRANSACTIONS:
            return {
                ...state,
                pendingTransactions: { transactions: action.payload, count: action.count },
            };
        case types.PENDING_TRANSACTIONS_COUNT:
            return {
                ...state,
                pendingCount: action.payload,
            };
        case types.COMPLETED_TRANSACTIONS_COUNT:
            return {
                ...state,
                completedCount: action.payload,
            };
        case types.ALL_TRANSACTIONS_DATA:
            return {
                ...state,
                allTransactionsData: action.payload
            }
        case types.TRANSACTIONS_BY_YEAR:
            return {
                ...state,
                transactionsByYear: action.payload
            }
        case types.RESET_STATE:
            return {
                ...state,
                transactionsByYear: [],
                pendingTransactions: { transactions: [], count: 0 },
            }
        default:
            return { ...state };
    }
};

export default usersReducer;
