import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUsersCount } from "../../redux/actions/dashboardAction";
import { Chart } from "primereact/chart";
import {
    getAllTransactionsCount,
    getCompletedTransactionsCount,
    getPendingTransactions,
    getPendingTransactionsCount,
    getTransactionByYear,
} from "../../redux/actions/transactionsActions";
import { Calendar } from "primereact/calendar";
import { getMappedCountByMonths, monthsArray } from "../../utils/constant";
import { getUsersByYear } from "../../redux/actions/userAction";
import PendingTransactions from "../Transactions/PendingTransactions";

export default function Dashboard({ name, history, socket }) {
    const [usersCount, setUsersCount] = useState({
        activeUsers: 0,
        inActiveUsers: 0,
    });

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(new Date());

    useEffect(() => {
        socket.on("updateTransactionsList", () => {
            dispatch(getTransactionByYear(setLoading, year.getFullYear()));
            dispatch(getPendingTransactions(setLoading, 1, 5, [], null, null));
            dispatch(getPendingTransactionsCount(setLoading));
            dispatch(getCompletedTransactionsCount(setLoading));
        });

        socket.on("updateUsersList", () => {
            dispatch(getUsersByYear(setLoading, year.getFullYear()));
            getUsersCount((data) => {
                setUsersCount(data);
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket.connected]);

    useEffect(() => {
        dispatch(
            getUsersCount((data) => {
                setUsersCount(data);
            })
        );
    }, [dispatch]);

    let { pendingCount, completedCount, transactionsByYear } = useSelector((state) => state.transactions);
    let { usersByYear } = useSelector((state) => state.users);

    useEffect(() => {
        dispatch(getPendingTransactionsCount(setLoading));
        dispatch(getCompletedTransactionsCount(setLoading));
        dispatch(getPendingTransactions(setLoading, 1, 5, [], null, null));
    }, [dispatch]);

    useEffect(() => {
        dispatch({ type: "RESET_STATE" });
        dispatch(getTransactionByYear(setLoading, year.getFullYear()));
        dispatch(getUsersByYear(setLoading, year.getFullYear()));
    }, [dispatch, year]);

    const basicData = {
        labels: monthsArray,
        datasets: [
            {
                label: "Transactions",
                data: transactionsByYear,
                fill: false,
                borderColor: "#42A5F5",
                tension: 0.4,
            },
            {
                label: "Users",
                data: usersByYear,
                fill: false,
                borderColor: "#FFA726",
                tension: 0.4,
            },
        ],
    };

    return (
        <div className="grid align-items-stretch">
            <div className="col-12 lg:col-6 xl:col-3 cursor-pointer">
                <div className="card mb-0 h-full" onClick={() => history.push(`/users`)}>
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Active Users</span>
                            <div className="text-900 font-medium text-xl">{usersCount?.activeUsers}</div>
                        </div>
                        <div
                            className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                        >
                            <i className="pi pi-users text-cyan-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-500">Total Active User</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3 cursor-pointer">
                <div className="card mb-0 h-full">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Inactive Users</span>
                            <div className="text-900 font-medium text-xl">{usersCount?.inActiveUsers}</div>
                        </div>
                        <div
                            className="flex align-items-center justify-content-center bg-red-100 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                        >
                            <i className="pi pi-user-minus text-red-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-500">Total InActive User</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3 cursor-pointer">
                <div className="card mb-0 h-full" onClick={() => history.push("/transactions/requests")}>
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Transaction Requests</span>
                            <div className="text-900 font-medium text-xl">{pendingCount}</div>
                        </div>
                        <div
                            className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                        >
                            <i className="pi pi-book text-cyan-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-500">No. Of Transaction Requests</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3 cursor-pointer">
                <div className="card mb-0 h-full">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Completed Transactions</span>
                            <div className="text-900 font-medium text-xl">{completedCount}</div>
                        </div>
                        <div
                            className="flex align-items-center justify-content-center bg-green-100 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                        >
                            <i className="pi pi-book text-green-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-500">No. Completed Transactions</span>
                </div>
            </div>

            <div className="col-12 lg:col-7 xl:col-7 cursor-pointer">
                <div className="card mb-0 h-full">
                    <label htmlFor="">Select Year: </label>
                    <Calendar view="year" dateFormat="yy" value={year} onChange={(e) => setYear(e.value)} />
                    <Chart type="line" data={basicData} />
                </div>
            </div>
            <div className="col-12 lg:col-5 xl:col-5 cursor-pointer">
                <div
                    className="card mb-0 h-full"
                    onClick={() => {
                        dispatch({ type: "RESET_STATE" });
                        history.push(`/transactions/requests`);
                    }}
                >
                    <PendingTransactions
                        showSearch={false}
                        heading={"Latest Pending Transactions"}
                        paginator={false}
                        hideColumns={["email", "status", "network", "gasFees", "date", "time"]}
                    />
                </div>
            </div>
        </div>
    );
}
