import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { editUser, getUser } from "../../redux/actions/userAction";

export default function CustomerContainer() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams();

    const [data, setData] = useState({});
    const ignoreFields = ["image"];

    useEffect(() => {
        if (id) {
            dispatch(
                getUser(id, (data) => {
                    setData({
                        // image: data.image ? [data.image] : [],
                        fullName: data?.fullName,
                        email: data?.email
                    });
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch]);

    const [formType, setFormType] = useState("EDIT");
    const [loading, setLoading] = useState(false);

    const handleChange = ({ name, value }) => {
        if (formType !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData, ignoreFields)) {
            if (formType === "EDIT") {
                dispatch(editUser(id, data, setLoading, history));
            }
        }
    };

    return { data, handleChange, loading, onSubmit, setFormType };
}
