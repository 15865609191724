export default class Constants {
    static END_POINT = {
        LOGIN: "/login",
        PROFILE: "/profile",
        FORGET_PASSWORD: "/forgot-password",
        UPDATE_PROFILE: "/update",
        RESET_PASSWORD: "/reset-password",
        UPLOAD_FILE: "/upload-files",
        CHANGE_PASSWORD: "/change-password",

        // ADMIN //
        ADMIN: "",
        UPDATE_ADMIN: "/update",

        //Dashborad
        GET_USERS_COUNT: "/users/count",

        //Users
        USERS: "/users/",
        ENABLE_DISABLE_USER: "/users/update-status/",

        // Transactions // 
        ALL_TRANSACTIONS: "/transactions/all-transactions",
        PENDING_TRANSACTIONS: "/transactions/pending-transactions",
        APPROVE_REJECT_TRANSACTION: "/transactions/update-status/",
        UPDATE_TRANSACTION: "/transactions/update/",
        PENDING_TRANSACTIONS_COUNT: "/pending-transactions/count",
        COMPLETED_TRANSACTIONS_COUNT: "/completed-transactions/count",
        ALL_TRANSACTIONS_DATA: "/all-transactions",
        TRANSACTIONS_BY_YEAR: "/get-transactions-count-by-year",
        USERS_BY_YEAR: "/get-users-count-by-year",
        // Gas Fees // 
        GAS_FEES: "/gas-fees",
        UPDATE_GAS_FEES: "/gas-fees/update/"
    };
}
