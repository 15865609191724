const menuItems = () => {
    return [
        {
            items: [
                {
                    icon: "pi pi-home",
                    label: "Home",
                    to: "/dashboard",
                },
                {
                    icon: "pi pi-user ",
                    label: "Users",
                    to: "/users",
                },
                {
                    icon: "pi pi-book",
                    label: "Transactions",
                    to: "/transactions/requests",
                    items: [
                        {
                            icon: "pi pi-home",
                            label: "Transaction Request",
                            to: "/transactions/requests",
                        },
                        {
                            icon: "pi pi-user ",
                            label: "Transactions History",
                            to: "/transactions/history",
                        }
                    ]
                },
                {
                    icon: "pi pi-money-bill",
                    label: "Gas Fees",
                    to: "/gas-fees",
                },
                {
                    icon: "pi pi-id-card",
                    label: "My Details",
                    to: "/my-details",
                }
            ],
        },
    ];
};

export { menuItems };
