import React, { useRef, useState } from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import * as moment from "moment";
import { confirmPopup } from "primereact/confirmpopup";
import { approveRejectTransaction, updateTransaction } from "../../redux/actions/transactionsActions";
import { CustomDropdown } from "../../shared/Input/CustomDropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";

export default function PendingTransactionsTable({
    heading,
    pendingTransactions,
    loading,
    search,
    setSearch,
    setCurrentPage,
    rows,
    setRows,
    first,
    setFirst,
    setFilterData,
    setSearchText,
    searchKeys,
    filterData,
    currentPage,
    setLoading,
    showSearch,
    paginator,
    hideColumns,
}) {
    const dispatch = useDispatch();
    const statusOptions = ["Pending", "Approved", "Processing", "Completed", "Rejected"];

    const status = (d) => {
        return (
            <div className="flex justify-content-around">
                <CustomDropdown
                    label={""}
                    placeholder=""
                    options={statusOptions}
                    optionLabel={""}
                    value={d.status}
                    onChange={(e) => handleStatusChange(e, d._id, e.target.value, d.userId)}
                ></CustomDropdown>
            </div>
        );
    };

    const handleStatusChange = (event, id, status, userId) => {
        confirm(event, id, status, userId);
    };

    const confirm = (event, id, status, userId) => {
        confirmPopup({
            target: event.currentTarget,
            message: `Are you sure you want to update this transaction?`,
            icon: "pi pi-exclamation-triangle",
            accept: () => accept(id, status, userId),
            reject,
        });
    };

    const accept = (id, status, userId) => {
        dispatch(approveRejectTransaction(id, { status, userId }, setLoading, currentPage, rows, searchKeys, search, filterData));
    };

    const reject = () => { };

    const [filters, setFilters] = useState({
        createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        network: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        status: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });

    const dateFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                dateFormat="dd-mm-yy"
                placeholder="dd-mm-yyyy"
                mask="99/99/9999"
            />
        );
    };

    const statusFilterTemplate = (options) => {
        return (
            <CustomDropdown
                options={["Pending", "Approved", "Processing"]}
                value={options.value}
                onChange={(e) => options.filterCallback(e.value, options.index)}
            ></CustomDropdown>
        );
    };

    const networkFilterTemplate = (options) => {
        return (
            <CustomDropdown
                options={["tron", "ethereum", "polygon"]}
                value={options.value}
                onChange={(e) => options.filterCallback(e.value, options.index)}
            ></CustomDropdown>
        );
    };

    const applyDateFilter = (e) => {
        for (let key of e.constraints) {
            let filterData = {};
            if (key.matchMode === "Date is before") {
                filterData = { ...filterData, endDate: moment(key.value).format("YYYY-MM-DD") };
            } else if (key.matchMode === "Date is after") {
                filterData = { ...filterData, startDate: moment(key.value).format("YYYY-MM-DD") };
            } else {
                filterData = {
                    ...filterData,
                    startDate: moment(key.value).format("YYYY-MM-DD"),
                    endDate: moment(key.value).format("YYYY-MM-DD"),
                };
            }
            setFilterData((prev) => ({ ...prev, createdAt: filterData }));
        }
    };

    const onApplyFilters = (e) => {
        for (let key in e.filters) {
            if (key === "createdAt" && e.filters["createdAt"].constraints[0].value) {
                applyDateFilter(e.filters[key]);
            } else if (e.filters[key].constraints[0].value) {
                setFilterData((prev) => ({ ...prev, [key]: e.filters[key].constraints[0].value }));
            }
        }
    };

    const onFilterClear = (key) => {
        setFilterData((prev) => {
            delete prev[key];
            return {
                ...prev,
            };
        });
    };

    const onRowEditComplete = (e) => {
        dispatch(updateTransaction(e.newData._id, { email: e.newData.email, gasFees: e.newData.gasFees, amountPaid: e.newData.amountPaid, amountReceived: e.newData.amountReceived }, setLoading, currentPage, rows, searchKeys, search, filterData));
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    return (
        <>
            <TableCard
                title={heading}
                onSearch={setSearch}
                searchKeyword={search}
                totalRecords={pendingTransactions.count}
                first={first}
                setFirst={setFirst}
                setCurrentPage={setCurrentPage}
                rows={rows}
                setRows={setRows}
                setSearchText={setSearchText}
                showSearch={showSearch}
                paginator={paginator}
            >
                <DataTable
                    value={pendingTransactions?.transactions}
                    showGridlines
                    filterDisplay="menu"
                    rows={rows}
                    editMode="row"
                    onRowEditComplete={onRowEditComplete}
                    loading={loading}
                    emptyMessage="No transactions found."
                    filters={filters}
                    onFilter={onApplyFilters}
                >
                    {/* <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column> */}
                    <Column body={(rowData, row) => row.rowIndex + 1 + first} header="SrNo"></Column>
                    {hideColumns.includes("date") ? null : (
                        <Column
                            body={(rowData, row) => moment(rowData.createdAt).format("YYYY-MM-DD")}
                            field="createdAt"
                            header="Date"
                            style={{ minWidth: "8rem" }}
                            dataType="date"
                            filter={true}
                            filterElement={dateFilterTemplate}
                            showFilterOperator={false}
                            filterMatchModeOptions={["Date is", "Date is before", "Date is after"]}
                            onFilterClear={() => onFilterClear("createdAt")}
                        ></Column>
                    )}
                    {hideColumns.includes("time") ? null : (
                        <Column body={(rowData, row) => moment(rowData.createdAt).format("HH:mm")} header="Time"></Column>
                    )}
                    {hideColumns.includes("walletAddress") ? null : <Column field="walletAddress" header="Address"></Column>}
                    {hideColumns.includes("email") ? null : <Column field="email" header="Email" editor={(options) => textEditor(options)}></Column>}
                    <Column
                        body={(rowData, row) => `${rowData.paidAmountCurrency} -  ${rowData.receivedAmountCurrency}`}
                        header="Pay In"
                        style={{ minWidth: "10rem" }}
                    ></Column>
                    {hideColumns.includes("network") ? null : (
                        <Column
                            field="network"
                            header="Network"
                            filter
                            filterElement={networkFilterTemplate}
                            showFilterMatchModes={false}
                            showAddButton={false}
                            showFilterOperator={false}
                            filterMatchModeOptions={["Equals"]}
                            onFilterClear={() => onFilterClear("network")}
                        ></Column>
                    )}
                    {hideColumns.includes("gasFees") ? null : <Column field="gasFees" header="Gas Fees" style={{ minWidth: "8rem" }} editor={(options) => textEditor(options)}></Column>}
                    {hideColumns.includes("amountReceived") ? null : (
                        <Column
                            field="amountPaid" 
                            header="Pay Amount" 
                            style={{ minWidth: "8rem" }} 
                            editor={(options) => textEditor(options)}
                        ></Column>
                    )}
                    {hideColumns.includes("amountReceived") ? null : (
                        <Column
                            field="amountReceived"
                            header="Receive Amount"
                            style={{ minWidth: "8rem" }}
                            editor={(options) => textEditor(options)}
                        ></Column>
                    )}
                    {hideColumns.includes("status") ? null : (
                        <Column
                            field="status"
                            header="Status"
                            body={status}
                            style={{ width: "80px" }}
                            filter
                            filterElement={statusFilterTemplate}
                            showFilterMatchModes={false}
                            showAddButton={false}
                            showFilterOperator={false}
                            filterMatchModeOptions={["Equals"]}
                            onFilterClear={() => onFilterClear("status")}
                        ></Column>
                    )}
                    <Column rowEditor headerStyle={{ width: "10%", minWidth: "8rem" }} bodyStyle={{ textAlign: "center" }}></Column>
                </DataTable>
            </TableCard>
        </>
    );
}
