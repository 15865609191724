import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";

const getGasFeesCurrencyList = (setLoading) => async (dispatch) => {
    console.log('reaeched')
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", Constants.END_POINT.GAS_FEES);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.GAS_FEES,
                payload: res.data
            });
        } else {
            dispatch({
                type: types.GAS_FEES,
                payload: []
            });
        }
    } else {
        dispatch({
            type: types.GAS_FEES,
            payload: []
        });
    }
    if (setLoading) {
        setLoading(false);
    }
};

const updateGasFees = (id, data, setLoading) => async (dispatch) => {
    setLoading(true);

    const payload = {
        ...data,
    };

    const res = await api("put", Constants.END_POINT.UPDATE_GAS_FEES + id, payload);
    if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(getGasFeesCurrencyList(setLoading));
    } else {
        dispatch(showToast({ severity: "success", summary: res.message }));
    }
};

export { getGasFeesCurrencyList, updateGasFees };
