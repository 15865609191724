import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";

const getAdminDetails = () => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", `${Constants.END_POINT.ADMIN}`);
    if (res.success) {
        dispatch(hideLoaderAction());
        if (res.data) {
            dispatch({
                type: types.ADMIN_DETAILS,
                payload: res.data,
            });
        }
    } else {
        dispatch({
            type: types.ADMIN_DETAILS,
            payload: {}
        });
    }
};

const updateAdmin = (data) => async (dispatch) => {
    const payload = {
        ...data,
    };
    dispatch(showLoaderAction());
    const res = await api("put", Constants.END_POINT.UPDATE_ADMIN, payload);
    if (res.success) {
        dispatch(hideLoaderAction());
        dispatch(getAdminDetails());
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export { getAdminDetails, updateAdmin };
