import { types } from "../types/types";
const intitalState = {
    admin: {}
};

const adminReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ADMIN_DETAILS:
            return {
                ...state,
                admin: action.payload

            };
        default:
            return { ...state };
    }
};

export default adminReducer;