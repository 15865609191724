import React, { useState } from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";
import * as moment from "moment";
import { CustomDropdown } from "../../shared/Input/CustomDropdown";

export default function AllTransactionsTable({
    heading,
    allTransactions,
    loading,
    history,
    search,
    setSearch,
    setCurrentPage,
    rows,
    setRows,
    first,
    setFirst,
    setFilterData,
    allTransactionsData,
    setSearchText,
}) {
    const [filters, setFilters] = useState({
        createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        network: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        status: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });

    const dateFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
                mask="99/99/9999"
            />
        );
    };

    const statusFilterTemplate = (options) => {
        return (
            <CustomDropdown
                options={["Pending", "Approved", "Processing", "Completed", "Rejected"]}
                value={options.value}
                onChange={(e) => options.filterCallback(e.value, options.index)}
            ></CustomDropdown>
        );
    };

    const networkFilterTemplate = (options) => {
        return (
            <CustomDropdown
                options={["tron", "ethereum", "polygon"]}
                value={options.value}
                onChange={(e) => options.filterCallback(e.value, options.index)}
            ></CustomDropdown>
        );
    };

    const applyDateFilter = (e) => {
        for (let key of e.constraints) {
            let filterData = {};
            if (key.matchMode === "Date is before") {
                filterData = { ...filterData, endDate: moment(key.value).format("YYYY-MM-DD") };
            } else if (key.matchMode === "Date is after") {
                filterData = { ...filterData, startDate: moment(key.value).format("YYYY-MM-DD") };
            } else {
                filterData = {
                    ...filterData,
                    startDate: moment(key.value).format("YYYY-MM-DD"),
                    endDate: moment(key.value).format("YYYY-MM-DD"),
                };
            }
            setFilterData((prev) => ({ ...prev, createdAt: filterData }));
        }
    };

    const onApplyFilters = (e) => {
        for (let key in e.filters) {
            if (key === "createdAt" && e.filters["createdAt"].constraints[0].value) {
                applyDateFilter(e.filters[key]);
            } else if (e.filters[key].constraints[0].value) {
                setFilterData((prev) => ({ ...prev, [key]: e.filters[key].constraints[0].value }));
            }
        }
    };

    const onFilterClear = (key) => {
        setFilterData((prev) => {
            delete prev[key];
            return {
                ...prev,
            };
        });
    };

    return (
        <>
            <TableCard
                title={heading}
                onSearch={setSearch}
                searchKeyword={search}
                totalRecords={allTransactions.count}
                first={first}
                setFirst={setFirst}
                setCurrentPage={setCurrentPage}
                rows={rows}
                setRows={setRows}
                exportData={true}
                exportDataList={allTransactionsData}
                setSearchText={setSearchText}
                paginator={true}
            >
                <DataTable
                    value={allTransactions.transactions}
                    showGridlines
                    filterDisplay="menu"
                    rows={rows}
                    loading={loading}
                    emptyMessage="No transactions found."
                    filters={filters}
                    onFilter={onApplyFilters}
                >
                    <Column body={(rowData, row) => row.rowIndex + first + 1} header="SrNo"></Column>
                    <Column
                        body={(rowData, row) => moment(rowData.createdAt).format("YYYY-MM-DD")}
                        field="createdAt"
                        header="Date"
                        style={{ minWidth: "8rem" }}
                        dataType="date"
                        filter
                        filterElement={dateFilterTemplate}
                        showFilterOperator={false}
                        filterMatchModeOptions={["Date is", "Date is before", "Date is after"]}
                        onFilterClear={() => onFilterClear("createdAt")}
                    ></Column>
                    <Column body={(rowData, row) => moment(rowData.createdAt).format("HH:mm")} header="Time"></Column>
                    <Column field="walletAddress" header="Address"></Column>
                    <Column field="email" header="Email"></Column>
                    <Column
                        body={(rowData, row) => `${rowData.paidAmountCurrency} -  ${rowData.receivedAmountCurrency}`}
                        header="Pay In"
                        style={{ minWidth: "10rem" }}
                    ></Column>
                    <Column
                        field="network"
                        header="Network"
                        filter
                        filterElement={networkFilterTemplate}
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showFilterOperator={false}
                        filterMatchModeOptions={["Equals"]}
                        onFilterClear={() => onFilterClear("network")}
                    ></Column>
                    <Column field="gasFees" header="Gas Fees" style={{ minWidth: "8rem" }}></Column>
                    <Column field="amountPaid" header="Pay Amount" style={{ minWidth: "8rem" }}></Column>
                    <Column field="amountReceived" header="Receive Amount" style={{ minWidth: "8rem" }}></Column>
                    <Column
                        field="status"
                        header="Status"
                        style={{ width: "80px" }}
                        filter
                        filterElement={statusFilterTemplate}
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showFilterOperator={false}
                        filterMatchModeOptions={["Equals"]}
                        onFilterClear={() => onFilterClear("status")}
                    ></Column>
                </DataTable>
            </TableCard>
        </>
    );
}
