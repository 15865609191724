import { types } from "../types/types";
const intitalState = {
    allUsers: [],
};

const dashboardReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CHANGE_USER_COUNT:
            return {
                ...state,
                allUsers: action.payload,
            };
        default:
            return { ...state };
    }
};

export default dashboardReducer;
