import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";

const getAllTransactions = (setLoading, page, limit, searchKeys, search, filterData) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }

    const res = await api("get", `${Constants.END_POINT.ALL_TRANSACTIONS}?page=${page}&limit=${limit}&searchKeys=${JSON.stringify(searchKeys)}&searchString=${search}&filterData=${JSON.stringify(filterData)}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ALL_TRANSACTIONS,
                payload: res.data,
                count: res.count
            });
        } else {
            dispatch({
                type: types.ALL_TRANSACTIONS,
                payload: [],
                count: 0
            });
        }
    } else {
        dispatch({
            type: types.ALL_TRANSACTIONS,
            payload: [],
            count: 0
        });
    }
    if (setLoading) {
        setLoading(false);
    }
};

const getPendingTransactionsCount = (setLoading) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", Constants.END_POINT.PENDING_TRANSACTIONS_COUNT);

    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.PENDING_TRANSACTIONS_COUNT,
                payload: res.count
            });
        } else {
            dispatch({
                type: types.PENDING_TRANSACTIONS_COUNT,
                count: 0
            });
        }
    } else {
        dispatch({
            type: types.PENDING_TRANSACTIONS_COUNT,
            count: 0
        });
    }
    if (setLoading) {
        setLoading(false);
    }
};

const getCompletedTransactionsCount = (setLoading) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", Constants.END_POINT.COMPLETED_TRANSACTIONS_COUNT);
    if (res.success) {
        dispatch({
            type: types.COMPLETED_TRANSACTIONS_COUNT,
            payload: res.count,
        });
    }
    if (setLoading) {
        setLoading(false);
    }
};

const getPendingTransactions = (setLoading, page, limit, searchKeys, search, filterData) => async (dispatch) => {

    const res = await api("get", `${Constants.END_POINT.PENDING_TRANSACTIONS}?page=${page}&limit=${limit}&searchKeys=${JSON.stringify(searchKeys)}
    &searchString=${search}&filterData=${JSON.stringify(filterData)}`);

    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.PENDING_TRANSACTIONS,
                payload: res.data,
                count: res.count
            });
        }
    } else {
        dispatch({
            type: types.PENDING_TRANSACTIONS,
            payload: [],
            count: 0
        });
    }
    if (setLoading) {
        setLoading(false);
    }
};

const getTransactionByYear = (setLoading, year) => async (dispatch) => {

    const res = await api("get", `${Constants.END_POINT.TRANSACTIONS_BY_YEAR}?year=${year}`);

    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.TRANSACTIONS_BY_YEAR,
                payload: res.data
            });
        }
    } else {
        dispatch({
            type: types.TRANSACTIONS_BY_YEAR,
            payload: []
        });
    }
    if (setLoading) {
        setLoading(false);
    }
};

const approveRejectTransaction = (id, data, setLoading, page, limit, searchKeys, search, filterData) => async (dispatch) => {
    const payload = {
        ...data,
    };

    const res = await api("put", Constants.END_POINT.APPROVE_REJECT_TRANSACTION + id, payload);
    if (res.success) {
        dispatch(getPendingTransactions(setLoading, page, limit, searchKeys, search, filterData));
        dispatch(showToast({ severity: "success", summary: res.message }));
    }
};

const updateTransaction = (id, data, setLoading, page, limit, searchKeys, search, filterData) => async (dispatch) => {
    const payload = {
        ...data,
    };

    const res = await api("put", Constants.END_POINT.UPDATE_TRANSACTION + id, payload);
    if (res.success) {
        dispatch(getPendingTransactions(setLoading, page, limit, searchKeys, search, filterData));
        dispatch(showToast({ severity: "success", summary: res.message }));
    }
};

const getAllTransactionsData = (setLoading) => async (dispatch) => {

    const res = await api("get", `${Constants.END_POINT.ALL_TRANSACTIONS_DATA}`);

    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ALL_TRANSACTIONS_DATA,
                payload: res.data
            });
        }
    } else {
        dispatch({
            type: types.ALL_TRANSACTIONS_DATA,
            payload: [],
        });
    }
    if (setLoading) {
        setLoading(false);
    }
};

export { getAllTransactions, getPendingTransactions, approveRejectTransaction, getPendingTransactionsCount, getCompletedTransactionsCount, getAllTransactionsData, getTransactionByYear, updateTransaction };
