import { combineReducers } from "redux";
import loaderReducer from "./loaderReducer";
import profileReducer from "./profileReducer";
import toastReducer from "./toastReducer";
import dashboardReducer from "./dashboardReducer";
import usersReducer from "./userReducer";
import transactionsreducer from "./transactionsReducer";
import uploadReducer from "./uploadReducer";
import gasFeesReducer from "./gasFeesReducer";
import adminReducer from './adminReducer';

export default combineReducers({
    toast: toastReducer,
    loader: loaderReducer,
    profile: profileReducer,
    dashboard: dashboardReducer,
    users: usersReducer,
    upload: uploadReducer,
    transactions: transactionsreducer,
    gasFees: gasFeesReducer,
    adminDetails: adminReducer
});
