import React from "react";

import BgImage from "../../assets/images/bg-image.jpg";

export default function Background({ children, heading }) {
    return (
        <div
            className="main-background"
            style={{
                // backgroundImage: `url(${BgImage})`,
                backgroundSize: "cover",
                minHeight: "100vh",
                backgroundPosition: "center",
            }}
        >
            <div className="p-3 h-full">{children}</div>
        </div>
    );
}
