import React, { useEffect } from "react";
import { CustomForm, CustomInput } from "../../shared/Input/AllInputs";
import UserContainer from "./UserContainer";
import PrimaryButton from "../../shared/Button/PrimaryButton";

const UserForm = ({ type }) => {
    const { data, handleChange, loading, onSubmit, setFormType } = UserContainer();
    useEffect(() => {
        setFormType(type);
    }, [type, setFormType]);

    return (
        <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
            {/* <CustomImageInput name="image" label="User Image" data={data} editable={false} /> */}
            <CustomInput label="Full Legal Name" name="fullName" data={data} onChange={handleChange} />
            <CustomInput label="Email Address" name="email" data={data} onChange={handleChange} />
            {type !== "VIEW" && <PrimaryButton label="Save Customer" loading={loading} onClick={onSubmit} col={6} />}
        </CustomForm>
    );
};

export default UserForm;
