import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDetails } from "../../redux/actions/adminAction";

export default function Index() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAdminDetails());
    }, [dispatch]);

    let { admin } = useSelector((state) => state.adminDetails);

    return {
        admin
    };
}
