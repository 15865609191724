import React from "react";
import { useHistory } from "react-router-dom";
import { confirmPopup } from "primereact/confirmpopup";
import { logout } from "./services/auth";
export default function AppTopBar({ toggle }) {
    const history = useHistory();

    const confirm = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Are you sure you want to Logout?",
            icon: "pi pi-exclamation-triangle",
            accept,
            reject,
        });
    };
    const accept = () => {
        logout(() => {
            setTimeout(() => {
                history.push("/login");
            }, 400);
        });
    };
    const reject = () => {};

    return (
        <div className="layout-topbar">
            <div>
                <i className="pi pi-bars layout-topbar-button text-xl" onClick={toggle}></i>
                <h2 className="mx-2 my-auto pb-2">Tinbox</h2>
            </div>
            <div>
                <span className="mx-5 text-xl my-auto"></span>
                <i className="pi pi-power-off my-auto text-2xl" onClick={confirm} />
            </div>
        </div>
    );
}
