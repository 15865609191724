import React from "react";
import MyDetailsTable from "./MyDetailsTable";
import Index from "./index";

const GasFees = () => {
    const { admin } = Index();

    return <MyDetailsTable heading="My Details" data={admin} />;
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};
export default React.memo(GasFees, comparisonFn);
