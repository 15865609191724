import React, { useState } from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { updateAdmin } from "../../redux/actions/adminAction";

export default function MyDetailsTable({ heading, data }) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const onRowEditComplete = (e) => {
        const payload = {
            eTransferMail: e.newData.eTransferMail,
            walletAddresses: e.newData.walletAddresses.map((item) => ({
                ...item,
                address: e.newData[item.network] ? e.newData[item.network] : item.address,
            })),
        };
        dispatch(updateAdmin(payload));
    };

    const dynamicColumns =
        data?.walletAddresses &&
        data?.walletAddresses.map((col, i) => {
            return (
                <Column
                    key={col.network}
                    field={col.network}
                    body={(rowData, row) => col.address}
                    header={col.network}
                    editor={(options) => {
                        options.value = col.address;
                        return textEditor(options);
                    }}
                />
            );
        });

    const textEditor = (options) => {
        return (
            <InputText
                type="text"
                value={options.rowData[options.field] || options.rowData[options.field] === "" ? options.rowData[options.field] : options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
            />
        );
    };

    return (
        <>
            <TableCard title={heading} pagination={true}>
                <DataTable
                    value={[data]}
                    showGridlines
                    loading={loading}
                    editMode="row"
                    onRowEditComplete={onRowEditComplete}
                    tableStyle={{ minWidth: "50rem" }}
                    emptyMessage="No details found."
                >
                    <Column
                        key={data?.eTransferMail}
                        field="eTransferMail"
                        body={(rowData, row) => (rowData.eTransferMail ? rowData.eTransferMail : "-")}
                        header={"E-Transfer Mail"}
                        editor={(options) => textEditor(options)}
                    ></Column>
                    {dynamicColumns}
                    <Column rowEditor headerStyle={{ width: "10%", minWidth: "8rem" }} bodyStyle={{ textAlign: "center" }}></Column>
                </DataTable>
            </TableCard>
        </>
    );
}
