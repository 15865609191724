import React from "react";
import AllTransactionsTable from "./AllTransactionsTable";
import Index from "./index";

const AllTransactions = () => {
    const {
        allTransactions,
        loading,
        history,
        search,
        setSearch,
        currentPage,
        setCurrentPage,
        rows,
        setRows,
        first,
        setFirst,
        setFilterData,
        allTransactionsData,
        setSearchText,
    } = Index();

    return (
        <AllTransactionsTable
            heading="All Transactions"
            allTransactions={allTransactions}
            loading={loading}
            history={history}
            search={search}
            setSearch={setSearch}
            rows={rows}
            setRows={setRows}
            first={first}
            setFirst={setFirst}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setFilterData={setFilterData}
            allTransactionsData={allTransactionsData}
            setSearchText={setSearchText}
        />
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};
export default React.memo(AllTransactions, comparisonFn);
