import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllTransactions, getAllTransactionsData, getPendingTransactions } from "../../redux/actions/transactionsActions";
import useDebounce from "../../utils/hooks";

export default function Index() {

    const history = useHistory();
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const searchKeys = ["walletAddress", "email", "network"];
    const [search, setSearch] = useState("");
    const [filterData, setFilterData] = useState({});

    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = useDebounce(500, "");

    useEffect(() => {
        if (history.location.pathname === '/transactions/requests') {
            dispatch(getPendingTransactions(setLoading, currentPage, rows, searchKeys, search, filterData));
        } else {
            dispatch(getAllTransactions(setLoading, currentPage, rows, searchKeys, search, filterData));
        }
    }, [dispatch, currentPage, rows, searchText, filterData]);

    let { allTransactions, pendingTransactions, allTransactionsData } = useSelector((state) => state.transactions);

    return {
        allTransactions,
        pendingTransactions,
        allTransactionsData,
        loading,
        setLoading,
        history,
        currentPage,
        rows,
        setRows,
        setCurrentPage,
        search,
        setSearch,
        first,
        setFirst,
        setFilterData,
        setSearchText,
        searchKeys,
        filterData
    };
}
