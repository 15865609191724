export const types = {
    SHOW_TOAST: "SHOW_TOAST",
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    SHOW_SIDEBAR_LOADER: "SHOW_SIDEBAR_LOADER",
    HIDE_SIDEBAR_LOADER: "HIDE_SIDEBAR_LOADER",
    USER_DETAIL: "USER_DETAIL",
    CHANGE_USERS: "CHANGE_USERS",
    USERS_BY_YEAR: "USERS_BY_YEAR",
    RESET_STATE: "RESET_STATE",

    ADMIN_DETAILS: "ADMIN_DETAILS",

    // Transactions //
    ALL_TRANSACTIONS: "ALL_TRANSACTIONS",
    PENDING_TRANSACTIONS: "PENDING_TRANSACTIONS",
    PENDING_TRANSACTIONS_COUNT: "PENDING_TRANSACTIONS_COUNT",
    COMPLETED_TRANSACTIONS_COUNT: "COMPLETED_TRANSACTIONS_COUNT",
    ALL_TRANSACTIONS_DATA: "ALL_TRANSACTIONS_DATA",
    TRANSACTIONS_BY_YEAR: "TRANSACTIONS_BY_YEAR",

    GAS_FEES: "GAS_FEES"
};
