import { types } from "../types/types";
const intitalState = {
    gasFees: [],
};

const gasFeesReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GAS_FEES:
            return {
                ...state,
                gasFees: action.payload,

            };
        default:
            return { ...state };
    }
};

export default gasFeesReducer;
