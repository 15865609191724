import React from "react";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import PrimaryButton from "../Button/PrimaryButton";
import CustomPaginator from "../CustomPaginator";
import { CSVLink, CSVDownload } from "react-csv";
import { useDispatch } from "react-redux";
import useDebounce from "../../utils/hooks";

export default function TableCard({
    title,
    onSearch,
    searchKeyword,
    buttonTitle,
    onClick,
    linkTo,
    children,
    extraButtons,
    totalRecords,
    first,
    rows,
    setFirst,
    currentPage,
    setCurrentPage,
    setRows,
    onPendingTransactionsPageChange,
    pagination,
    exportData = false,
    exportDataList = [],
    setSearchText,
    showSearch,
    paginator,
}) {
    const history = useHistory();

    return (
        <div className="label-table">
            {title || onSearch || linkTo || buttonTitle ? (
                <div className="py-2 px-3 flex justify-content-between w-full m-0" style={{ backgroundColor: "#071426" }}>
                    <div className="text-2xl">{title}</div>
                    <div className="flex justify-content-end flex-wrap">
                        <div>
                            {showSearch ? (
                                <div className="p-input-icon-left mx-2">
                                    <i className="pi pi-search" />
                                    <InputText
                                        value={searchKeyword}
                                        onChange={(e) => {
                                            onSearch(e.target.value);
                                            setSearchText(e.target.value);
                                        }}
                                        placeholder="Search"
                                    />
                                </div>
                            ) : null}
                        </div>

                        <div className="flex align-items-center justify-content-center">
                            {exportData ? <CSVLink data={exportDataList}>Export All</CSVLink> : null}
                        </div>

                        {linkTo ? (
                            <PrimaryButton severity="secondary" className="mt-2 md:mt-0" onClick={() => history.push(linkTo)} label={buttonTitle} />
                        ) : buttonTitle ? (
                            <PrimaryButton severity="secondary" className="mt-2 md:mt-0" onClick={onClick} label={buttonTitle} />
                        ) : null}

                        {extraButtons &&
                            extraButtons.length > 0 &&
                            extraButtons.map((item) => (
                                <>
                                    {item.linkTo ? (
                                        <PrimaryButton
                                            severity="secondary"
                                            className="mt-2 md:mt-0 ml-2"
                                            onClick={() => history.push(item.linkTo)}
                                            label={item.buttonTitle}
                                        />
                                    ) : buttonTitle ? (
                                        <PrimaryButton
                                            severity="secondary"
                                            className="mt-2 md:mt-0 ml-2"
                                            onClick={item.onClick}
                                            label={item.buttonTitle}
                                        />
                                    ) : null}
                                </>
                            ))}
                    </div>
                </div>
            ) : null}
            <div className="content-2 overflow-hidden">{children}</div>
            {!pagination && paginator ? (
                <CustomPaginator
                    first={first}
                    setFirst={setFirst}
                    rows={rows}
                    setCurrentPage={setCurrentPage}
                    totalRecords={totalRecords}
                    setRows={setRows}
                ></CustomPaginator>
            ) : null}
        </div>
    );
}
