import Dashboard from "../views/Dashboard/Dashboard";
import Users from "../views/Users/Users";
import ViewUser from "../views/Users/ViewUser";
import EditUser from "../views/Users/EditUser";
import AllTransactions from "../views/Transactions/AllTransactions";
import PendingTransactions from "../views/Transactions/PendingTransactions";
import GasFees from "../views/GasFees/GasFees";
import { socket } from "../socket";
import MyDetails from "../views/MyDetails/MyDetails";

export const AppRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        exact: true,
        socket: socket
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
        exact: true,
    },
    {
        path: "/users/view/:id",
        name: " View User",
        component: ViewUser,
        exact: true,
    },
    {
        path: "/users/edit/:id",
        name: " Edit User",
        component: EditUser,
        exact: true,
    },
    {
        path: "/transactions/history",
        name: "Transactions",
        component: AllTransactions,
        exact: true,
    },
    {
        path: "/transactions/requests",
        name: "Pending Transactions",
        component: PendingTransactions,
        exact: true,
    },
    {
        path: "/gas-fees",
        name: "Gas Fee Curreny List",
        component: GasFees,
        exact: true,
    },
    {
        path: "/my-details",
        name: "My Details",
        component: MyDetails,
        exact: true,
    },
];
