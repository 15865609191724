import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";

const getUsers = (setLoading, page, limit, searchKeys, search, filterKey, filterData) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", `${Constants.END_POINT.USERS}?page=${page}&limit=${limit}&searchKeys=${JSON.stringify(searchKeys)}&searchString=${search}&filterKey=${filterKey}&filterData=${JSON.stringify(filterData)}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CHANGE_USERS,
                payload: res.data,
                count: res.count
            });
        }
    } else {
        dispatch({
            type: types.CHANGE_USERS,
            payload: [],
            count: 0
        });
    }
    if (setLoading) {
        setLoading(false);
    }
};

const UserEnableDisableAction = (id, deactivate) => async (dispatch) => {
    const res = await api("put", Constants.END_POINT.ENABLE_DISABLE_USER + id, deactivate);
    if (res.success) {
        dispatch(getUsers());
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

const getUser = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.USERS + id);
    if (res.success) {
        dispatch(hideLoaderAction());
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    }
    dispatch(hideLoaderAction());
};

const editUser = (id, data, setLoading, history) => async (dispatch) => {
    setLoading(true);

    const payload = {
        ...data,
    };

    const res = await api("put", Constants.END_POINT.USERS + id, payload);
    if (res.success) {
        getUsers(null);
        history.goBack();
        dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
};

const getUsersByYear = (setLoading, year) => async (dispatch) => {

    const res = await api("get", `${Constants.END_POINT.USERS_BY_YEAR}?year=${year}`);

    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.USERS_BY_YEAR,
                payload: res.data
            });
        }
    } else {
        dispatch({
            type: types.USERS_BY_YEAR,
            payload: []
        });
    }
    if (setLoading) {
        setLoading(false);
    }
};

export { getUsers, getUser, UserEnableDisableAction, editUser, getUsersByYear };
